.tileProfilo{
    width: calc(100vw - 60px);
    display: flex;
    height: 48px;
    cursor: pointer;
}

.tileProfiloDark{
    color: var(--primaryDark);
}

.tileProfiloLight{
    color: var(--blu);
}

.frameTileProfilo{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 30px;
}

.contenitoreToggleProfilo{    
    display: flex;
    flex-direction: row;
    align-items: center;
}

.rettangoloToggle{
    height: 24px;
    width: 48px;
    border-radius: 1000px;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0 3px;
}

.cerchioToggle{
    height: 20px;
    width: 20px;
    border-radius: 20px; 
    transition: all 800ms;
}

.rettangoloToggleDark{
    border: 1px solid var(--primaryDark);
    background-color: var(--sfondoDark);
    justify-content: flex-end;
}

.rettangoloToggleLight{
    border: 1px solid var(--blu);
    background-color: var(--sfondoLight);
    justify-content: flex-start;
}

.cerchioToggleDark{
    background-color: var(--primaryDark);  
}

.cerchioToggleLight{
    background-color: var(--blu);  
}

.titleTileProfilo{
    font-weight: 400;
    font-size: 20px;
}

.iconaProfiloDarkStroke{
    stroke: var(--primaryDark);
}

.iconaProfiloDarkfill{
    fill: var(--primaryDark);
}

.iconaProfiloLightStroke{
    stroke: var(--blu);
}

.iconaProfiloLightfill{
    fill: var(--blu);
}

@media only screen and (min-width: 1200px) {
    .tileProfilo{
        max-width: 30vw;
    }

    .titleTileProfilo{
        font-size: 20px;
    }
}