.primoAccesso{
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1010;
    width: 100vw;
    height: 100%;
    box-sizing: border-box;
    background-color: var(--bluSecondario);
    color: var(--bianco);
    text-align: center;
    padding: 30px;
}

.frame1PrimoAccesso{
    font-weight: 400;
    font-size: 20px;

    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    align-content: center;
    align-items: center;
    gap: 30px;

    overflow-y: scroll;

    /* hide scroll bar */
    -ms-overflow-style: none;  /* Internet Explorer 10+ */
    scrollbar-width: none;  /* Firefox */
}

.frame1PrimoAccesso::-webkit-scrollbar { 
    /* hide scroll bar */
    display: none;  /* Safari and Chrome */
}


.iniziamoPrimoAccesso{
    font-weight: 400;
    font-size: 20px;
    display: flex;
    flex-flow: column wrap;
    justify-content: center;
    align-items: center;
    gap: 10px;
    font-style: normal;
    cursor: pointer;
}

.titlePrimoAccesso{
    font-weight: 600;
    font-size: 35px;
    line-height: 60px;
}

.logoLinguaPrimoAccesso{
    display: flex;
    flex-flow: column wrap;
    gap: 20px;
}

.linguaPrimoAccesso{
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    gap: 5px;
    min-width: 48px;
    min-height: 48px;
    cursor: pointer;
    text-decoration: underline;
}

.linguaPrimoAccesso svg *{
    fill: var(--bianco);
}


@media only screen and (max-height: 587px) {
    .frame1PrimoAccesso{
        justify-content: flex-start;
    }
}

.frame2PrimoAccesso{
    background-color: #035EB3;

    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-content: center;
    align-items: center;
    box-sizing: border-box;
}

.popUpPrimoAccesso{
    background-color: var(--bianco);
    width: 100%;
    border-radius: 10px;
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    align-items: center;
    max-height: 80%;
    box-sizing: border-box;
    padding: 30px 20px 10px;
}

.titolettoPrimoAccesso{
    font-weight: 600;
    font-size: 15px;
    line-height: 20px;
    box-sizing: border-box;
    margin-top: 10px;
    margin-bottom: 15px;
}

.consensoPrimoAccesso{
    font-weight: 300;
    font-size: 15px;
    line-height: 20px;
    text-align: left;
    box-sizing: border-box;
}

.titolettoPrimoAccesso, .consensoPrimoAccesso{
    color: var(--blu);
}

.sopraPopUpPrimoAccesso{
    max-height: 70%;
    box-sizing: border-box;

    overflow-y: scroll;

    /* hide scroll bar */
    -ms-overflow-style: none;  /* Internet Explorer 10+ */
    scrollbar-width: none;  /* Firefox */
}

.sopraPopUpPrimoAccesso::-webkit-scrollbar { 
    /* hide scroll bar */
    display: none;  /* Safari and Chrome */
}

.bottoniPopUpPrimoAccesso{
    width: 100%;
    display: flex;
    flex-flow: column nowrap;
    gap: 10px;
    padding: 10px 0;
    box-sizing: border-box;
}

.bottonePopUpPrimoAccesso{
    background-color: var(--bluSecondario);
    border-radius: 1000px;
    width: 100%;
    height: 48px;
    line-height: 48px;
    cursor: pointer;
}