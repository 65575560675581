.popSelect{
    border-radius: 10px;
    width: calc(100vw - 70px);
    display: flex;
    flex-flow: column;
    align-items: center;
}

.popSelectDark{
    background-color: var(--backgroundTileDark);
    color: var(--primaryDark);
}

.popSelectLight{
    background-color: var(--bianco);
    color: var(--blu);
}

.titlePopSelect{    
    font-weight: 600;
    font-size: 24px;
    width: 100%;
    padding: 10px 20px;
    box-sizing: border-box;
    text-align: left;
}

.titlePopSelectDark{
    box-shadow: inset 0px -1px 0px var(--primaryDark80);
}

.titlePopSelectLight{
    box-shadow: inset 0px -1px 0px var(--blu50);
}

.btnPopSelect{
    font-weight: 600;
    font-size: 15px;
    letter-spacing: 1.25px;
    text-transform: uppercase;
    box-sizing: border-box;
    height: 56px;
    padding: 0 20px;
    display: flex;
    flex-flow: row;
    justify-content: flex-end;
    align-items: center;
    gap: 25px;
    width: 100%;
}

.btnPopSelectDark{
    color: var(--secondaryDark);
    box-shadow: inset 0px 1px 0px var(--primaryDark80);
}

.btnPopSelectLight{
    color: var(--bluSecondario);
    box-shadow: inset 0px 1px 0px var(--blu50);
}

.btnTextPopSelect{
    height: 48px;
    display: flex;
    flex-flow: column;
    justify-content: center;
    text-align: center;
    cursor: pointer;
}

.frameOpzioniPopSelect{
    max-height: calc(100vh - 65px * 2);
    overflow-y: auto;
    padding: 10px;
    box-sizing: border-box;
    width: 100%;
    display: flex;
    flex-flow: column;
    gap: 20px;
}

.tilePopSelect{
    min-height: 48px;
    width: 100%;
    display: flex;
    flex-flow: row wrap;
    gap: 30px;
    cursor: pointer;
}

.txtTilePopSelect{
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    height: 48px;
    gap: 0px;
    text-align: left;
}

.txtTilePopSelectTitle{
    font-weight: 600;
    font-size: 18px;
}

.txtTilePopSelectTxt{
    font-weight: 300;
    font-size: 14px;
}

.frameRadioBtn{
    height: 48px;
    width: 48px;
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
}

.cerchioRadioBtn{
    height: 14px;
    width: 14px;
    border-radius: 1000px;
}

.borderRadioBtnDark{
    box-sizing: border-box;
    border: 2px solid var(--secondaryDark);
    height: 24px;
    width: 24px;
    border-radius: 1000px;
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
}

.borderRadioBtnLight{
    box-sizing: border-box;
    border: 2px solid var(--bluSecondario);
    height: 24px;
    width: 24px;
    border-radius: 1000px;
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
}

@media only screen and (min-width: 570px) {
    .popSelect{
        width: 500px;
    }
}