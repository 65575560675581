.ricettaHome{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px 10px 20px;
    border-radius: 20px;
    gap: 15px;
    min-height: calc(58vw * 9/16 + 45px + 48px);
    width: fit-content;
    box-sizing: border-box;
    cursor: pointer;
}

.lightRicettaTile{
    background: var(--bianco);
    box-shadow: var(--ombra);
    color: var(--blu);
}

.lightRicettaTile path{
    fill: var(--blu);
}

.darkRicettaTile{
    background: var(--backgroundTileDark);
    color: var(--primaryDark);
}

.darkRicettaTile path{
    fill: var(--primaryDark);
}

.fotoRicettaTile{
    background-position: center;
    background-size: cover;
    border-radius: 20px;
    display: flex;
    align-items: flex-end;

}

.fotoRicettaHome{    
    width: 58vw;
    height: calc(58vw * 9/16);
}

.tempoTileRicetta{
    padding: 5px 10px 5px 0;
    display: flex;
    gap: 10px;
    width: 100%;
    align-items: center;
    justify-content: flex-end;
    font-size: 14px;
    font-weight: 400;
    box-sizing: border-box;
    border-radius: 0 0 20px 20px;
}

.tempoTileRicettaDark{
    background: rgba(31, 31, 31, 0.8);    
}

.tempoTileRicettaLight{
    background: rgba(250, 251, 251, 0.8);
}

.titoloRicettaHome{
    width: 58vw;
    text-align: center;
    font-weight: 600;
    font-size: 16px;
}

.ricettaRicettario1{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px 10px 20px;
    border-radius: 20px;
    gap: 15px;
    min-height: calc((100vw - 80px) * 9/16 + 45px + 48px);
    width: fit-content;
    box-sizing: border-box;
    cursor: pointer;
}


.ricettaRicettario2{
    display: flex;
    flex-direction: row;
    align-items: stretch;
    padding: 10px 0 10px 10px;
    border-radius: 20px;
    width: calc(100vw - 60px);
    min-height: 130px;
    box-sizing: border-box;
    gap: 10px;
    cursor: pointer;
}

.fotoRicettaRicettario1{
    width: calc(100vw - 80px);
    height: calc((100vw - 80px)*9/16);
}

.fotoRicettaRicettario2{
    width: 130px;
    min-height: 110px;
    border-radius: 18px 0px 0px 18px;
}

.titoloRicettaRicettario1{
    width: calc(100vw - 80px);
    text-align: center;
    font-weight: 600;
    font-size: 16px;
}

.descRicettaRicettario2{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    font-weight: 600;
    font-size: 16px;
    flex: 1;
}

@media only screen and (min-width: 700px) {
    .fotoRicettaHome{    
        width: 27vw;
        height: calc(27vw * 9/16);
    }

    .titoloRicettaHome{
        font-size: 20px;
    }

    .titoloRicettaRicettario1{
        font-size: 20px;
    }

    .descRicettaRicettario2{
        font-size: 20px;
    }

    .ricettaHome{
        min-height: calc(27vw * 9/16 + 45px + 62px);
    }

    .titoloRicettaHome{
        width: 27vw;
    }

    .fotoRicettaRicettario1{
        width: 25vw;
        height: calc(25vw * 9/16);
    }
    
    .ricettaRicettario1{
        min-height: calc(25vw * 9/16 + 45px + 62px);
    }

    .titoloRicettaRicettario1{
        width: 25vw;
    }

    .ricettaRicettario2{
        width: 25vw;
    }
    
    @media only screen and (min-width: 1200px) {
        .tempoTileRicetta{
            font-size: 16px;
        }
    }
}