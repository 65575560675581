.formSpreadSheet{
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-start;
    align-content: center;
    align-items: center;
    width: 100vw;
    height: 100vh;
    padding: 80px 30px;
    box-sizing: border-box;
    gap: 20px;
    overflow-y: auto;
}

.urlFormSpreadsheet{
    margin-bottom: 50px;
}

.flexColMsgUrl{
    display: flex;
    flex-flow: column nowrap;
}

button{
    cursor: pointer;
}

.inputTextForm{
    width: 100%;
}

.inputTextForm input, .inputTextForm select{
    border: var(--grigio) solid 1px;
    border-radius: 5px;
    height: 30px;
    text-indent: 5px;
    width: 100%;
}

.inputTextForm input::placeholder{
    text-indent: 5px;
}

.descriprionInputForm{
    font-weight: 600;
    font-size: 18px;
}

.navButtonFormSpreadsheet{
    display: flex;
    flex-flow: row;
    justify-content: center;
    gap: 40px;
    width: 100%;
}

.flexInputTempoForm{
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: center;
    gap: 10px;
}

.messaggioCompilazioneForm{
    color: red;
    font-size: 30px;
    font-weight: 600;
    text-align: center;
}

.inputPassword{
    border: var(--grigio) solid 1px;
    border-radius: 5px;
    height: 30px;
    text-indent: 5px;
    width: 100%;
}

.flexPassword{
    display: flex;
    flex-flow: row;
    align-items: center;
}

.flexCaricamentoImmagine{
    display: flex;
    flex-flow: column;
}

.TitoloRecap{
    color: rgb(67, 123, 227);
    font-weight: 600;
}

.divImmagineForm{
    display: flex;
    flex-flow: row;
    align-items: center;
    gap: 10px;
}

.immagineForm{
    width: 30vw;
}

@media only screen and (min-width: 500px) {
    .inputTextForm{
        width: 48%;
        min-width: 48%;
    }
    @media only screen and (min-width: 1200px) {
        .inputTextForm{
            width: 30%;
            min-width: 30%;
        }
    }
}

.popUpCaricamentoForm{
    position: fixed;
    width: 250px;
    height: 200px;
    border-radius: 20px;
    background-color: var(--blu10);
    display: flex;
    flex-flow: column wrap;
    justify-content: center;
    align-items: center;
    align-content: center;
    gap: 20px;
    color: var(--blu);
    top: 100px;
}