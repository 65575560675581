.filtri{    
    display: flex;
    flex-flow: column wrap;
    align-items: center;
    gap: 20px;
    width: calc(100vw - 60px);
    padding-bottom: 120px;
}

.sezioneFiltro{
    display: flex;
    flex-flow: column wrap;
    gap: 5px;
    width: 100%;
}

.slideFiltriFiltro{
    display: flex;
    flex-flow: row wrap;
    gap: 10px;
    padding-bottom: 10px;
}

.titoloSezioneFiltro{
    font-weight: 600;
    font-size: 18px;
}

.titoloSezioneFiltroDark{
    color: var(--primaryDark);
}

.titoloSezioneFiltroLight{
    color: var(--blu);
}

.vediAppFiltrare{
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    padding: 0 20px;
    border-radius: 1000px;
    cursor: pointer;
    width: max-content;
    height: 48px;
    position: fixed;
    bottom: 50px;
    font-weight: 400;
}

@media only screen and (min-width: 1200px) {
    .titoloSezioneFiltro{
        font-size: 22px;
    }
}