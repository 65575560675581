*{
  margin: 0;
  padding: 0;
  font-family: "Titillium Web",Arial,sans-serif;
}

:root {
  --blu: #002445;
  --bianco: #ffffff;
  --bordi: #E7E7E7;
  --blu80: #33506A;
  --blu50: #8091A2;
  --blu10: #E5E9EC;
  --grigio: #808080;;
  --sfondoLight: #FAFBFB;
  --bluSecondario: #0486FF;
  --primaryDark: #85C4FF;
  --sfondoDark: #121212;
  --primaryDark80: #71A3D2;
  --primaryDark50: #52718F;
  --primaryDark20: #33404C;
  --secondaryDark: #FFE985;
  --backgroundTileDark: #1D242A;
  --ombra: -1px 1px 10px rgba(0, 0, 0, 0.25);
}

/* Remove Pull-to-refresh */
body {
  overscroll-behavior-y: contain;
}

a{
  all: unset;
}

.chargingDataPage{
  display: flex;
  flex-flow: column wrap;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: calc(100vh - 100px);
}

.loadingSvg{
  width: 50vw;
}

.loadingSvgDark path{
  fill: var(--secondaryDark);
}

@media (prefers-reduced-motion: no-preference) {
  .loadingSvg {
    animation: loadingSvg-spin infinite 3s linear;
  }
}

@keyframes loadingSvg-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
