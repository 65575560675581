.user{
    display: flex;
    flex-flow: column wrap;
    gap: 20px;
}

.sharePopUp{
    position: fixed;
    width: 100vw;
    left: 0;
    transition: all 300ms;
    z-index: 2000;
    border-radius: 30px 30px 0px 0px;
    max-height: 100vh;
    overflow-y: auto;
}

.sharePopUpShow{
    bottom: 0;
    transition: all 300ms;
}

.sharePopUpHidden{
    bottom: -500px;
    transition: all 300ms;
    /* display: none; */
}

.rettangoloblurShow{
    position: fixed;
    z-index: 1500;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.7);
    visibility: visible;
    transition: all 100ms;
}

.rettangoloblurHidden{
    position: fixed;
    z-index: 1500;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.7);
    visibility: hidden;
    transition: all 100ms;
}

.selectPopUp{
    position: fixed;
    z-index: 1500;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-content: center;
    align-items: center;
}

.selectPopUpShow{
    visibility: visible;
}

.selectPopUpHidden{
    visibility: hidden;
}