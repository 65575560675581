.dettaglioApp{
    display: flex;
    flex-flow: column wrap;
    align-items: center;
    gap: 20px;
    margin-top: 60px;
}

.fotoRicettaDettaglio{
    background-position: center;
    background-size: cover;
    width: 100vw;
    height: calc(100vw * 9/16);
}

.dettaglioAppBody{
    box-sizing: border-box;
    padding: 0 30px;
    width: 100vw;
    display: flex;
    flex-flow: column wrap;
    align-items: flex-start;
    gap: 30px;
}

.dettaglioAppDark{
    color: var(--primaryDark);
}

.dettaglioAppLight{
    color: var(--blu);
}

.appSubTitle{
    font-weight: 600;
    font-size: 18px;
}

.appFlexSezioni{    
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    width: 100%;
}

.appTestoNote{
    font-weight: 300;
    font-size: 16px;
}

.appChip{
    border-radius: 10px;
    box-sizing: border-box;
    padding: 2px 10px;
    width: max-content;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    gap: 5px;
}

.appChipDark{
    color: var(--primaryDark);
    background-color: var(--backgroundTileDark);
}

.appChipDark path{
    fill: var(--primaryDark);
}

.appChipLight{
    color: var(--blu);
    border: 0.8px solid var(--blu);
    background-color: var(--sfondoLight);
}

.appChipLight path {
    fill: var(--blu);
}

.slideChipsApp{
    display: flex;
    flex-flow: row nowrap;
    align-items: stretch;
    gap: 10px;
    max-width: calc(100vw - 60px);
    overflow-x: auto;
    padding: 5px;
    box-sizing: border-box;
}

.appFlexTileDati{
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-content: center;
    align-items: center;
    width: 100%;
}

.tileDatiPrincipaliApp{
    display: flex;
    flex-flow: column wrap;
    justify-content: center;
    align-content: center;
    align-items: center;
    width: 25%;
}

.tileDatiPrincipaliAppTxt{
    font-weight: 300;
    font-size: 13px;
}

.appTable{
    display: flex;
    flex-flow: column wrap;
    justify-content: space-between;
    align-content: center;
    width: 100%;
    gap: 10px;
}

.appTableRow{
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-content: center;
    align-items: stretch;
    width: 100%;
    gap: 2px;
}

.appTableCol{
    display: flex;
    flex-flow: column wrap;
    justify-content: center;
    align-content: center;
    align-items: center;
}

.appTableValue{
    font-weight: 600;
    font-size: 24px;
    text-align: center;
}

.appTableDescription{
    font-weight: 400;
    font-size: 13px;    
    line-height: 20px;
    letter-spacing: 0.4px;
    text-align: center;
}

.unitaDiMisura{
    font-weight: 300;
}

.rigaV{
    width: 1px;
    border-radius: 1000px;
}

.rigaDark{
    background-color: var(--primaryDark50);
}

.rigaLight{
    background-color: var(--blu50);
}

.rigaO{
    height: 1px;
    border-radius: 1000px;
    width: 100%;
}

.appIconStrokeDark path{
    stroke: var(--primaryDark);
}
.appIconFillDark path{
    fill: var(--primaryDark);
}

.slideTileFunzioneApp{
    display: flex;
    flex-flow: row nowrap;
    align-items: stretch;
    gap: 20px;
    max-width: calc(100vw - 60px);
    overflow-x: auto;
    padding: 10px;
    box-sizing: border-box;
}

.appTileFunzione{
    min-height: calc(100vw *0.47);
    min-width: calc(100vw *0.47);
    border-radius: 5px;
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    align-items: center;
    padding: 5px;
    text-align: center;
}

.appTileFunzioneDark{
    background-color: var(--backgroundTileDark);
}

.appTileFunzioneLight{
    background-color: var(--sfondoLight);
    box-shadow: var(--ombra);
}

.appTileTitle{
    font-weight: 400;
    font-size: 13px;    
    line-height: 20px;
    letter-spacing: 0.4px;
}

.appTileTime, .appTileIntervallo{
    font-weight: 300;
    font-size: 18px;
    letter-spacing: 0.15px;
}

.appGraficoTempo{
    min-width: calc(46% + 20px);
    min-height: calc(46% + 20px);
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
}

.cerchioTrasparenteAppTile{
    width: 90%;
    height: 90%;
    border-radius: 50%;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
}

@media only screen and (max-width: 1200px) {
    .slideChipsApp{
        /* hide scroll bar */
        -ms-overflow-style: none;  /* Internet Explorer 10+ */
        scrollbar-width: none;  /* Firefox */
    }
    
    .slideChipsApp::-webkit-scrollbar { 
        /* hide scroll bar */
        display: none;  /* Safari and Chrome */
    }

    .slideTileFunzioneApp{
        /* hide scroll bar */
        -ms-overflow-style: none;  /* Internet Explorer 10+ */
        scrollbar-width: none;  /* Firefox */
    }
    
    .slideTileFunzioneApp::-webkit-scrollbar { 
        /* hide scroll bar */
        display: none;  /* Safari and Chrome */
    }
}

.appFlexSubtitleAndFrecce{
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-content: center;
    align-items: center;
    width: 100%;
}

.appFlexArrows{
    display: flex;
    flex-flow: row wrap;
}

.appFlexArrowsDark svg{
    stroke: var(--primaryDark);
    fill: none;
}

.appFlexArrowsLight svg{
    stroke: var(--blu);
    fill: none;
}

.notGreyArrowApp{
    cursor: pointer;
    transition: opacity 800ms;
}

.greyArrowApp{
    opacity: 0.3;
    transition: opacity 800ms;
}

.appTileFunzione{
    transition: all 800ms;
}

@media only screen and (min-width: 700px){
    .appTileFunzione{
        min-height: 170px;
        min-width: 170px;
    }
    @media only screen and (min-width: 1200px) {
        .appSubTitle{
            font-size: 22px;
        }

        .tileDatiPrincipaliAppTxt{
            font-size: 15px;
        }

        .appTileTitle{
            font-size: 15px;
        }

        .appTileIntervallo{
            font-size: 18px;
        }

        .appTileTime{
            font-size: 18px;
            padding: 40% 0 40%;
        }

        .appTestoNote{
            font-size: 18px;
        }

        .appTableDescription{
            font-size: 15px;
        }

        .appTableValue{
            font-size: 26px;
        }

        .appGraficoTempo{            
            min-width: calc(48% + 20px);
            min-height: calc(48% + 20px);
        }

        .fotoRicettaDettaglio{           
            flex: 1;
            border-radius: 5px;
            width: 30%;
            height: calc(40vw * 9/16);
        }

        .slideTileFunzioneApp{
            overflow-x: hidden;
        }
        .dettaglioAppBody{
            padding: 0;
            width: 60%;
        }

        .dettaglioApp{
            width: calc(100% - 100px);
            flex-flow: row;
            gap: 50px;
            margin-top: 0;
        }

        .tileDatiPrincipaliApp{
            flex-flow: row;
            justify-content: flex-start;
            min-height: 48px;
            width: auto;
        }

        .appFlexTileDati{
            justify-content: flex-start;
            gap: 40px;
        }

        .valueTableDesktop{
            width: 60%;
        }

        .slideTileFunzioneApp{
            max-width: 100%;
        }
    }
}