.header{    
    width: 100vw;
    height: 60px;
    display: flex;
    flex-flow: row nowrap;    
    justify-content: space-between;
    align-items: center;
    padding: 0 4px 0 20px;
    box-sizing: border-box;
    position: fixed;
    top: -0;
    z-index: 1000;
}

.headerDark{
    background-color: var(--backgroundTileDark);
}

.headerLight{
    box-shadow: 0px 1px 7px rgba(0, 0, 0, 0.25);
    background-color: var(--sfondoLight);
}

.headerAppDark{
    background: rgba(31, 31, 31, 0.7); 
}

.headerAppLight{
    box-shadow: 0px 1px 7px rgba(0, 0, 0, 0.25);
    background: rgba(250, 251, 251, 0.7);
}

.searchBoxHead{
    width: 48px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.searchBoxHeadDark path{
    stroke: var(--bianco);
}

.searchBoxHeadLight path{
    stroke: var(--blu);
}

.inputHeader{
    all: unset;
    flex: 1;
}

.inputDark{
    color: var(--primaryDark);
}

.inputLight{
    color: var(--blu);
}

.crossBoxHeadDark path{
    fill: var(--bianco);
}

.crossBoxHeadLight path{
    fill: var(--blu);
}

.headerRicerca{
    padding: 0 15px 0 0;
    transition: background 300ms linear;
}

.flexCrossInput{
    display: flex;
    flex-flow: row nowrap;
    gap: 10px;
    flex: 1;
    align-items: center;
    min-width: 0;
}

.resetHeader{
    display: flex;
    height: 100%;
    align-items: center;
    cursor: pointer;
    font-size: 16px;
}

.resetHeaderDark{
    color: var(--secondaryDark);
}

.resetHeaderLight{
    color: var(--bluSecondario);
}

input::placeholder{
    color: var(--grigio);
}

.titoloSezioneDark{
    font-weight: 600;
    font-size: 24px;
    color: var(--bianco);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.titoloSezioneLight{
    font-weight: 600;
    font-size: 24px;    
    color: var(--blu);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.iconaCondividiApp{
    display: flex;
    flex-flow: column wrap;
    justify-content: center;
    align-content: center;
    align-items: center;
    height: 48px;
    width: 48px;
    cursor: pointer;
}

.iconaCondividiAppDark{
    fill: var(--bianco);
}


@media only screen and (min-width: 1200px) {
    .resetHeader{
        font-size: 20px;
    }

    .linkHeaderDesktop{
        font-weight: 600;
        font-size: 16px;
        letter-spacing: 1.25px;
        text-transform: uppercase;
        cursor: pointer;
        min-height: 48px;
        min-width: 48px;
        line-height: 48px;
    }

    .linkHeaderDesktopDark{
        color: var(--bianco);
    }

    .linkHeaderDesktopLight{
        color: var(--blu);
    }    

    .flexRowNavbarDesktop{
        display: flex;
        flex-flow: row;
        justify-content: center;
        align-items: center;
        gap: 30px;
    }

    .flexRowRicercaDesktop{
        display: flex;
        flex-flow: row;
        justify-content: center;
        align-items: center;
    }

    .header{    
        padding: 0 30px 0 30px;
    }

    .headerRicerca{
        padding: 0 30px 0 30px;
    }

    .flexCrossInput{
        flex: none;
    }
}