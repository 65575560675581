.home{
    display: flex;
    flex-flow: column wrap;
    gap: 20px;
}

.tileCategoriaHome{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.titoloCategoriaHome{
    margin-left: 10px;
    font-weight: 700;
    font-size: 20px;
    cursor: pointer;
}

.slideApplicazioniHome{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    max-width: calc(100vw - 40px);
    overflow-x: scroll;
    padding: 10px;
    box-sizing: border-box;
}

.frecciaAltroDark path{
    fill: var(--primaryDark);
}

.frecciaAltroLight path{
    fill: var(--blu);
}

.altroHome{
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-left: 10px;
    box-sizing: border-box;
    gap: 10px;
    height: 48px;
    font-weight: 400;
    font-size: 16px;
    cursor: pointer;
}

.altroHomeDark{
    color: var(--primaryDark);
}

.altroHomeLight{
    color: var(--blu);
}

@media only screen and (min-width: 1200px) {    
    .slideApplicazioniHome{
        
        max-width: calc(100vw - 40px);
        overflow-x: hidden;
    }

    .titoloCategoriaHome{
        font-size: 22px;
    }

    .altroHome{
        font-size: 18px;
    }
}

@media only screen and (max-width: 1200px) {    
    .slideApplicazioniHome{
        /* hide scroll bar */
        -ms-overflow-style: none;  /* Internet Explorer 10+ */
        scrollbar-width: none;  /* Firefox */
    }
    
    .slideApplicazioniHome::-webkit-scrollbar { 
        /* hide scroll bar */
        display: none;  /* Safari and Chrome */
    }
    
    body{
        /* hide scroll bar */
        -ms-overflow-style: none;  /* Internet Explorer 10+ */
        scrollbar-width: none;  /* Firefox */
    }
    
    body::-webkit-scrollbar { 
        /* hide scroll bar */
        display: none;  /* Safari and Chrome */
    }
}