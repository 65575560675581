body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

*{
  margin: 0;
  padding: 0;
  font-family: "Titillium Web",Arial,sans-serif;
}

:root {
  --blu: #002445;
  --bianco: #ffffff;
  --bordi: #E7E7E7;
  --blu80: #33506A;
  --blu50: #8091A2;
  --blu10: #E5E9EC;
  --grigio: #808080;;
  --sfondoLight: #FAFBFB;
  --bluSecondario: #0486FF;
  --primaryDark: #85C4FF;
  --sfondoDark: #121212;
  --primaryDark80: #71A3D2;
  --primaryDark50: #52718F;
  --primaryDark20: #33404C;
  --secondaryDark: #FFE985;
  --backgroundTileDark: #1D242A;
  --ombra: -1px 1px 10px rgba(0, 0, 0, 0.25);
}

/* Remove Pull-to-refresh */
body {
  overscroll-behavior-y: contain;
}

a{
  all: unset;
}

.chargingDataPage{
  display: flex;
  flex-flow: column wrap;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: calc(100vh - 100px);
}

.loadingSvg{
  width: 50vw;
}

.loadingSvgDark path{
  fill: #FFE985;
  fill: var(--secondaryDark);
}

@media (prefers-reduced-motion: no-preference) {
  .loadingSvg {
    -webkit-animation: loadingSvg-spin infinite 3s linear;
            animation: loadingSvg-spin infinite 3s linear;
  }
}

@-webkit-keyframes loadingSvg-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes loadingSvg-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.ricettaHome{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px 10px 20px;
    border-radius: 20px;
    grid-gap: 15px;
    gap: 15px;
    min-height: calc(58vw * 9/16 + 45px + 48px);
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    box-sizing: border-box;
    cursor: pointer;
}

.lightRicettaTile{
    background: var(--bianco);
    box-shadow: var(--ombra);
    color: var(--blu);
}

.lightRicettaTile path{
    fill: var(--blu);
}

.darkRicettaTile{
    background: var(--backgroundTileDark);
    color: var(--primaryDark);
}

.darkRicettaTile path{
    fill: var(--primaryDark);
}

.fotoRicettaTile{
    background-position: center;
    background-size: cover;
    border-radius: 20px;
    display: flex;
    align-items: flex-end;

}

.fotoRicettaHome{    
    width: 58vw;
    height: calc(58vw * 9/16);
}

.tempoTileRicetta{
    padding: 5px 10px 5px 0;
    display: flex;
    grid-gap: 10px;
    gap: 10px;
    width: 100%;
    align-items: center;
    justify-content: flex-end;
    font-size: 14px;
    font-weight: 400;
    box-sizing: border-box;
    border-radius: 0 0 20px 20px;
}

.tempoTileRicettaDark{
    background: rgba(31, 31, 31, 0.8);    
}

.tempoTileRicettaLight{
    background: rgba(250, 251, 251, 0.8);
}

.titoloRicettaHome{
    width: 58vw;
    text-align: center;
    font-weight: 600;
    font-size: 16px;
}

.ricettaRicettario1{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px 10px 20px;
    border-radius: 20px;
    grid-gap: 15px;
    gap: 15px;
    min-height: calc((100vw - 80px) * 9/16 + 45px + 48px);
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    box-sizing: border-box;
    cursor: pointer;
}


.ricettaRicettario2{
    display: flex;
    flex-direction: row;
    align-items: stretch;
    padding: 10px 0 10px 10px;
    border-radius: 20px;
    width: calc(100vw - 60px);
    min-height: 130px;
    box-sizing: border-box;
    grid-gap: 10px;
    gap: 10px;
    cursor: pointer;
}

.fotoRicettaRicettario1{
    width: calc(100vw - 80px);
    height: calc((100vw - 80px)*9/16);
}

.fotoRicettaRicettario2{
    width: 130px;
    min-height: 110px;
    border-radius: 18px 0px 0px 18px;
}

.titoloRicettaRicettario1{
    width: calc(100vw - 80px);
    text-align: center;
    font-weight: 600;
    font-size: 16px;
}

.descRicettaRicettario2{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    font-weight: 600;
    font-size: 16px;
    flex: 1 1;
}

@media only screen and (min-width: 700px) {
    .fotoRicettaHome{    
        width: 27vw;
        height: calc(27vw * 9/16);
    }

    .titoloRicettaHome{
        font-size: 20px;
    }

    .titoloRicettaRicettario1{
        font-size: 20px;
    }

    .descRicettaRicettario2{
        font-size: 20px;
    }

    .ricettaHome{
        min-height: calc(27vw * 9/16 + 45px + 62px);
    }

    .titoloRicettaHome{
        width: 27vw;
    }

    .fotoRicettaRicettario1{
        width: 25vw;
        height: calc(25vw * 9/16);
    }
    
    .ricettaRicettario1{
        min-height: calc(25vw * 9/16 + 45px + 62px);
    }

    .titoloRicettaRicettario1{
        width: 25vw;
    }

    .ricettaRicettario2{
        width: 25vw;
    }
    
    @media only screen and (min-width: 1200px) {
        .tempoTileRicetta{
            font-size: 16px;
        }
    }
}
.home{
    display: flex;
    flex-flow: column wrap;
    grid-gap: 20px;
    gap: 20px;
}

.tileCategoriaHome{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.titoloCategoriaHome{
    margin-left: 10px;
    font-weight: 700;
    font-size: 20px;
    cursor: pointer;
}

.slideApplicazioniHome{
    display: flex;
    flex-direction: row;
    align-items: center;
    grid-gap: 10px;
    gap: 10px;
    max-width: calc(100vw - 40px);
    overflow-x: scroll;
    padding: 10px;
    box-sizing: border-box;
}

.frecciaAltroDark path{
    fill: var(--primaryDark);
}

.frecciaAltroLight path{
    fill: var(--blu);
}

.altroHome{
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-left: 10px;
    box-sizing: border-box;
    grid-gap: 10px;
    gap: 10px;
    height: 48px;
    font-weight: 400;
    font-size: 16px;
    cursor: pointer;
}

.altroHomeDark{
    color: var(--primaryDark);
}

.altroHomeLight{
    color: var(--blu);
}

@media only screen and (min-width: 1200px) {    
    .slideApplicazioniHome{
        
        max-width: calc(100vw - 40px);
        overflow-x: hidden;
    }

    .titoloCategoriaHome{
        font-size: 22px;
    }

    .altroHome{
        font-size: 18px;
    }
}

@media only screen and (max-width: 1200px) {    
    .slideApplicazioniHome{
        /* hide scroll bar */
        -ms-overflow-style: none;  /* Internet Explorer 10+ */
        scrollbar-width: none;  /* Firefox */
    }
    
    .slideApplicazioniHome::-webkit-scrollbar { 
        /* hide scroll bar */
        display: none;  /* Safari and Chrome */
    }
    
    body{
        /* hide scroll bar */
        -ms-overflow-style: none;  /* Internet Explorer 10+ */
        scrollbar-width: none;  /* Firefox */
    }
    
    body::-webkit-scrollbar { 
        /* hide scroll bar */
        display: none;  /* Safari and Chrome */
    }
}
.tour{
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1010;
    width: 100vw;
    height: 100%;
    box-sizing: border-box;
    text-align: center;
    background-color: transparent;
    transition: background-color 1000ms linear;
}

.paginaBluTour{
    background-color: var(--bluSecondario);
    color: var(--bianco);
    padding: 30px 30px 100px;

    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    align-items: center;
}

.skipTour{
    font-weight: 600;
    font-size: 15px;    
    letter-spacing: 1.25px;
    min-height: 48px;
    min-width: 48px;
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    grid-gap: 5px;
    gap: 5px;
    z-index: 1100;
}

.skipPaginaBlu{
    position: fixed;
    bottom: 10px;
    right: 30px;
    color: var(--bianco);
}

.txtPrimoAccesso{
    font-weight: 400;
    font-size: 20px;
}

.iconaNavBarTour{
    border-radius: 1000px;
    background-color: var(--sfondoLight);
    height: 48px;
    width: 48px;
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
}

.paginaTutorial{
    background-color: rgba(0, 0, 0, 0.3);
}


.flexMidPaginaTutorial{
    z-index: 1100;
    position: fixed;
    bottom: 20%;

    font-weight: 400;
    font-size: 20px;

    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    width: 100%;

    grid-gap: 10px;

    gap: 10px;

    box-sizing: border-box;
    padding: 0 30px;
}

.txtPaginaTutorial{
    max-width: 100%; 
    color: transparent;
    transition: all 800ms;
}

.txtPaginaTutorialEnd{
    color: var(--bianco);   
}

.continuaPaginaTour{
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: center;
    grid-gap: 10px;
    gap: 10px;
    font-style: normal;
    cursor: pointer;
    min-width: 48px;
    min-height: 48px;
    color: transparent;
    transition: all 800ms;
}

.continuaPaginaTour svg path{
    fill: transparent;
    transition: all 800ms;
}

.continuaPaginaTourEnd{
    color: var(--bianco);
}

.continuaPaginaTourEnd svg path{
    fill: var(--bianco);
}



.skipPaginaTutorial{
    position: fixed;
    bottom: 60px;
    right: 30px;
    color: transparent;
    transition: all 800ms;
}

.skipPaginaTutorial svg path{
    stroke: transparent;
    transition: all 800ms;
}

.skipPaginaTutorialEnd{
    color: var(--bianco);
}

.skipPaginaTutorialEnd svg path{
    stroke: var(--bianco);
    transition: all 800ms;
}

.cerchioTourBegin{
    width: 0px;
    height: 0px;
    transition: all 1000ms cubic-bezier(0, 0, 0.57, 1.49);
    border-radius: 1000px;
    position: fixed;
    border: 0px solid var(--bluSecondario);
}

.cerchioTourBeginCenter{
    bottom: 0px;
    left: 50vw;
}


.cerchioTourBeginLeft{
    bottom: 0px;
    left: 13vw;
}

.cerchioTourBeginRight{
    bottom: 0px;
    left: calc(100vw - 13vw);
}

.cerchioTourEndCenter{
    bottom: -50vh;
    left: calc(50vw - 50vh);
    border-width: 50vh;
}

.cerchioTourEndLeft{
    bottom: -55vh;
    left: calc(13vw - 55vh);
    border-width: 55vh;
}

.cerchioTourEndRight{
    bottom: -55vh;
    left: calc(100vw - 13vw - 55vh);
    border-width: 55vh;
}

@media only screen and (max-height: 730px) {
    .cerchioTourEndCenter{
        bottom: -55vh;
        left: calc(50vw - 55vh);
        border-width: 55vh;
    }

    .cerchioTourEndLeft{
        bottom: -65vh;
        left: calc(13vw - 65vh);
        border-width: 65vh;
    }
    
    .cerchioTourEndRight{
        bottom: -65vh;
        left: calc(100vw - 13vw - 65vh);
        border-width: 65vh;
    }

    @media only screen and (max-height: 450px) {
        .flexMidPaginaTutorial{
            bottom: 120px;
        }
    }
}

@media only screen and (max-width: 340px) {
    .cerchioTourEndCenter{
        bottom: -65vh;
        left: calc(50vw - 65vh);
        border-width: 65vh;
    }

    .cerchioTourEndLeft{
        bottom: -67vh;
        left: calc(13vw - 67vh);
        border-width: 67vh;
    }
    
    .cerchioTourEndRight{
        bottom: -67vh;
        left: calc(100vw - 13vw - 67vh);
        border-width: 67vh;
    }
}

@media (orientation: landscape) {

    .cerchioTourEndCenter{
        bottom: -62vw;
        left: calc(50vw - 62vw);
        border-width: 62vw;
    }
    
    .cerchioTourEndLeft{
        bottom: -87vw;
        left: calc(13vw - 87vw);
        border-width: 87vw;
    }
    
    .cerchioTourEndRight{
        bottom: -87vw;
        left: calc(100vw - 13vw - 87vw);
        border-width: 87vw;
    }

    @media only screen and (max-width: 600px) {
        .txtPaginaTutorial {
            width: 95%;
        }
    }
}
.primoAccesso{
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1010;
    width: 100vw;
    height: 100%;
    box-sizing: border-box;
    background-color: var(--bluSecondario);
    color: var(--bianco);
    text-align: center;
    padding: 30px;
}

.frame1PrimoAccesso{
    font-weight: 400;
    font-size: 20px;

    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    align-content: center;
    align-items: center;
    grid-gap: 30px;
    gap: 30px;

    overflow-y: scroll;

    /* hide scroll bar */
    -ms-overflow-style: none;  /* Internet Explorer 10+ */
    scrollbar-width: none;  /* Firefox */
}

.frame1PrimoAccesso::-webkit-scrollbar { 
    /* hide scroll bar */
    display: none;  /* Safari and Chrome */
}


.iniziamoPrimoAccesso{
    font-weight: 400;
    font-size: 20px;
    display: flex;
    flex-flow: column wrap;
    justify-content: center;
    align-items: center;
    grid-gap: 10px;
    gap: 10px;
    font-style: normal;
    cursor: pointer;
}

.titlePrimoAccesso{
    font-weight: 600;
    font-size: 35px;
    line-height: 60px;
}

.logoLinguaPrimoAccesso{
    display: flex;
    flex-flow: column wrap;
    grid-gap: 20px;
    gap: 20px;
}

.linguaPrimoAccesso{
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    grid-gap: 5px;
    gap: 5px;
    min-width: 48px;
    min-height: 48px;
    cursor: pointer;
    text-decoration: underline;
}

.linguaPrimoAccesso svg *{
    fill: var(--bianco);
}


@media only screen and (max-height: 587px) {
    .frame1PrimoAccesso{
        justify-content: flex-start;
    }
}

.frame2PrimoAccesso{
    background-color: #035EB3;

    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-content: center;
    align-items: center;
    box-sizing: border-box;
}

.popUpPrimoAccesso{
    background-color: var(--bianco);
    width: 100%;
    border-radius: 10px;
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    align-items: center;
    max-height: 80%;
    box-sizing: border-box;
    padding: 30px 20px 10px;
}

.titolettoPrimoAccesso{
    font-weight: 600;
    font-size: 15px;
    line-height: 20px;
    box-sizing: border-box;
    margin-top: 10px;
    margin-bottom: 15px;
}

.consensoPrimoAccesso{
    font-weight: 300;
    font-size: 15px;
    line-height: 20px;
    text-align: left;
    box-sizing: border-box;
}

.titolettoPrimoAccesso, .consensoPrimoAccesso{
    color: var(--blu);
}

.sopraPopUpPrimoAccesso{
    max-height: 70%;
    box-sizing: border-box;

    overflow-y: scroll;

    /* hide scroll bar */
    -ms-overflow-style: none;  /* Internet Explorer 10+ */
    scrollbar-width: none;  /* Firefox */
}

.sopraPopUpPrimoAccesso::-webkit-scrollbar { 
    /* hide scroll bar */
    display: none;  /* Safari and Chrome */
}

.bottoniPopUpPrimoAccesso{
    width: 100%;
    display: flex;
    flex-flow: column nowrap;
    grid-gap: 10px;
    gap: 10px;
    padding: 10px 0;
    box-sizing: border-box;
}

.bottonePopUpPrimoAccesso{
    background-color: var(--bluSecondario);
    border-radius: 1000px;
    width: 100%;
    height: 48px;
    line-height: 48px;
    cursor: pointer;
}
.popSelect{
    border-radius: 10px;
    width: calc(100vw - 70px);
    display: flex;
    flex-flow: column;
    align-items: center;
}

.popSelectDark{
    background-color: var(--backgroundTileDark);
    color: var(--primaryDark);
}

.popSelectLight{
    background-color: var(--bianco);
    color: var(--blu);
}

.titlePopSelect{    
    font-weight: 600;
    font-size: 24px;
    width: 100%;
    padding: 10px 20px;
    box-sizing: border-box;
    text-align: left;
}

.titlePopSelectDark{
    box-shadow: inset 0px -1px 0px var(--primaryDark80);
}

.titlePopSelectLight{
    box-shadow: inset 0px -1px 0px var(--blu50);
}

.btnPopSelect{
    font-weight: 600;
    font-size: 15px;
    letter-spacing: 1.25px;
    text-transform: uppercase;
    box-sizing: border-box;
    height: 56px;
    padding: 0 20px;
    display: flex;
    flex-flow: row;
    justify-content: flex-end;
    align-items: center;
    grid-gap: 25px;
    gap: 25px;
    width: 100%;
}

.btnPopSelectDark{
    color: var(--secondaryDark);
    box-shadow: inset 0px 1px 0px var(--primaryDark80);
}

.btnPopSelectLight{
    color: var(--bluSecondario);
    box-shadow: inset 0px 1px 0px var(--blu50);
}

.btnTextPopSelect{
    height: 48px;
    display: flex;
    flex-flow: column;
    justify-content: center;
    text-align: center;
    cursor: pointer;
}

.frameOpzioniPopSelect{
    max-height: calc(100vh - 65px * 2);
    overflow-y: auto;
    padding: 10px;
    box-sizing: border-box;
    width: 100%;
    display: flex;
    flex-flow: column;
    grid-gap: 20px;
    gap: 20px;
}

.tilePopSelect{
    min-height: 48px;
    width: 100%;
    display: flex;
    flex-flow: row wrap;
    grid-gap: 30px;
    gap: 30px;
    cursor: pointer;
}

.txtTilePopSelect{
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    height: 48px;
    grid-gap: 0px;
    gap: 0px;
    text-align: left;
}

.txtTilePopSelectTitle{
    font-weight: 600;
    font-size: 18px;
}

.txtTilePopSelectTxt{
    font-weight: 300;
    font-size: 14px;
}

.frameRadioBtn{
    height: 48px;
    width: 48px;
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
}

.cerchioRadioBtn{
    height: 14px;
    width: 14px;
    border-radius: 1000px;
}

.borderRadioBtnDark{
    box-sizing: border-box;
    border: 2px solid var(--secondaryDark);
    height: 24px;
    width: 24px;
    border-radius: 1000px;
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
}

.borderRadioBtnLight{
    box-sizing: border-box;
    border: 2px solid var(--bluSecondario);
    height: 24px;
    width: 24px;
    border-radius: 1000px;
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
}

@media only screen and (min-width: 570px) {
    .popSelect{
        width: 500px;
    }
}
.header{    
    width: 100vw;
    height: 60px;
    display: flex;
    flex-flow: row nowrap;    
    justify-content: space-between;
    align-items: center;
    padding: 0 4px 0 20px;
    box-sizing: border-box;
    position: fixed;
    top: -0;
    z-index: 1000;
}

.headerDark{
    background-color: var(--backgroundTileDark);
}

.headerLight{
    box-shadow: 0px 1px 7px rgba(0, 0, 0, 0.25);
    background-color: var(--sfondoLight);
}

.headerAppDark{
    background: rgba(31, 31, 31, 0.7); 
}

.headerAppLight{
    box-shadow: 0px 1px 7px rgba(0, 0, 0, 0.25);
    background: rgba(250, 251, 251, 0.7);
}

.searchBoxHead{
    width: 48px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.searchBoxHeadDark path{
    stroke: var(--bianco);
}

.searchBoxHeadLight path{
    stroke: var(--blu);
}

.inputHeader{
    all: unset;
    flex: 1 1;
}

.inputDark{
    color: var(--primaryDark);
}

.inputLight{
    color: var(--blu);
}

.crossBoxHeadDark path{
    fill: var(--bianco);
}

.crossBoxHeadLight path{
    fill: var(--blu);
}

.headerRicerca{
    padding: 0 15px 0 0;
    transition: background 300ms linear;
}

.flexCrossInput{
    display: flex;
    flex-flow: row nowrap;
    grid-gap: 10px;
    gap: 10px;
    flex: 1 1;
    align-items: center;
    min-width: 0;
}

.resetHeader{
    display: flex;
    height: 100%;
    align-items: center;
    cursor: pointer;
    font-size: 16px;
}

.resetHeaderDark{
    color: var(--secondaryDark);
}

.resetHeaderLight{
    color: var(--bluSecondario);
}

input::-webkit-input-placeholder{
    color: var(--grigio);
}

input:-ms-input-placeholder{
    color: var(--grigio);
}

input::placeholder{
    color: var(--grigio);
}

.titoloSezioneDark{
    font-weight: 600;
    font-size: 24px;
    color: var(--bianco);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.titoloSezioneLight{
    font-weight: 600;
    font-size: 24px;    
    color: var(--blu);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.iconaCondividiApp{
    display: flex;
    flex-flow: column wrap;
    justify-content: center;
    align-content: center;
    align-items: center;
    height: 48px;
    width: 48px;
    cursor: pointer;
}

.iconaCondividiAppDark{
    fill: var(--bianco);
}


@media only screen and (min-width: 1200px) {
    .resetHeader{
        font-size: 20px;
    }

    .linkHeaderDesktop{
        font-weight: 600;
        font-size: 16px;
        letter-spacing: 1.25px;
        text-transform: uppercase;
        cursor: pointer;
        min-height: 48px;
        min-width: 48px;
        line-height: 48px;
    }

    .linkHeaderDesktopDark{
        color: var(--bianco);
    }

    .linkHeaderDesktopLight{
        color: var(--blu);
    }    

    .flexRowNavbarDesktop{
        display: flex;
        flex-flow: row;
        justify-content: center;
        align-items: center;
        grid-gap: 30px;
        gap: 30px;
    }

    .flexRowRicercaDesktop{
        display: flex;
        flex-flow: row;
        justify-content: center;
        align-items: center;
    }

    .header{    
        padding: 0 30px 0 30px;
    }

    .headerRicerca{
        padding: 0 30px 0 30px;
    }

    .flexCrossInput{
        flex: none;
    }
}
.share{    
    padding: 10px 30px 20px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-weight: 600;
    grid-gap: 30px;
    gap: 30px;
    border-radius: 30px 30px 0px 0px;
    text-align: center;
}

.shareDark{
    background-color: var(--backgroundTileDark);
    color: var(--primaryDark);
}

.shareLight{
    background-color: var(--sfondoLight);
    color: var(--blu);
}

.shareTitle{
    font-size: 20px;
}

.bottoneAnnullaShareDark{
    width: 100%;
    height: 48px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: var(--primaryDark20);
    border-radius: 1000px;
}

.bottoneAnnullaShareLight{
    width: 100%;
    height: 48px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: var(--blu10);
    border-radius: 1000px;
}

.slideIconeShare{
    width: 100%;
    display: flex;
    flex-flow: row wrap;
}

.tileShare{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 33%;
    padding: 0 5px;
    box-sizing: border-box;
    cursor: pointer;
}

.colIconeShare{
    display: flex;
    width: 100%;
    flex-flow: column;
    grid-gap: 25px;
    gap: 25px;
}

@media only screen and (min-width: 700px) {
    .share{    
        padding: 10px 20vw 20px;
    }
}
.navbar{
    width: 100vw;
    height: 60px;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    padding: 0 30px;
    box-sizing: border-box;
    position: fixed;
    bottom: 0;
    z-index: 1000;
}

.navbarDark{
    background-color: var(--backgroundTileDark);
}

.navbarLight{
    box-shadow: 0px -1px 7px rgba(0, 0, 0, 0.25);
    background-color: var(--sfondoLight);
}

.iconaNavBar{
    width: 48px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.iconaNavBarSvgDark path{
    stroke: var(--bianco);
}

.iconaNavBarSvgLight path{
    stroke: var(--blu);
}

.iconaUserDark path{
    fill: var(--bianco);
}

.iconaUserLight path{
    fill: var(--blu);
}

.currentPageDark, .currentPageUserDark{
    box-shadow: inset 0px 3px 0px var(--secondaryDark);
}

.currentPageLight, .currentPageUserLight{
    box-shadow: inset 0px 3px 0px var(--bluSecondario);
}

.currentPageDark svg path{
    stroke: var(--secondaryDark);
}

.currentPageLight svg path{
    stroke: var(--bluSecondario);
}

.currentPageUserDark svg path{
    fill: var(--secondaryDark);
}

.currentPageUserLight svg path{
    fill: var(--bluSecondario);
}
.wrapper{
    display: flex;
    flex-flow: column;
    align-items: center;
    padding: 80px 0;
}

.wrapper2{    
    display: flex;
    flex-flow: column;
    align-items: center;
    padding: 80px 0 30px;
}

.wrapper3{
    display: flex;
    flex-flow: column;
    align-items: center;
    padding: 0 0 30px; 
}

@media only screen and (min-width: 1200px) {
    .wrapper{
        padding: 80px 0 30px;
    }

    .wrapper3{
        padding: 80px 0 30px; 
    }
}

.categoria{
    display: flex;
    flex-flow: column wrap;
    grid-gap: 20px;
    gap: 20px;
}

@media only screen and (min-width: 700px) {
    .categoria{
        flex-direction: row;
        justify-content: center;
        max-width: calc(100vw - 60px);
        grid-gap: 25px;
        gap: 25px;
    }
}
.tileProfilo{
    width: calc(100vw - 60px);
    display: flex;
    height: 48px;
    cursor: pointer;
}

.tileProfiloDark{
    color: var(--primaryDark);
}

.tileProfiloLight{
    color: var(--blu);
}

.frameTileProfilo{
    display: flex;
    flex-direction: row;
    align-items: center;
    grid-gap: 30px;
    gap: 30px;
}

.contenitoreToggleProfilo{    
    display: flex;
    flex-direction: row;
    align-items: center;
}

.rettangoloToggle{
    height: 24px;
    width: 48px;
    border-radius: 1000px;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0 3px;
}

.cerchioToggle{
    height: 20px;
    width: 20px;
    border-radius: 20px; 
    transition: all 800ms;
}

.rettangoloToggleDark{
    border: 1px solid var(--primaryDark);
    background-color: var(--sfondoDark);
    justify-content: flex-end;
}

.rettangoloToggleLight{
    border: 1px solid var(--blu);
    background-color: var(--sfondoLight);
    justify-content: flex-start;
}

.cerchioToggleDark{
    background-color: var(--primaryDark);  
}

.cerchioToggleLight{
    background-color: var(--blu);  
}

.titleTileProfilo{
    font-weight: 400;
    font-size: 20px;
}

.iconaProfiloDarkStroke{
    stroke: var(--primaryDark);
}

.iconaProfiloDarkfill{
    fill: var(--primaryDark);
}

.iconaProfiloLightStroke{
    stroke: var(--blu);
}

.iconaProfiloLightfill{
    fill: var(--blu);
}

@media only screen and (min-width: 1200px) {
    .tileProfilo{
        max-width: 30vw;
    }

    .titleTileProfilo{
        font-size: 20px;
    }
}
.user{
    display: flex;
    flex-flow: column wrap;
    grid-gap: 20px;
    gap: 20px;
}

.sharePopUp{
    position: fixed;
    width: 100vw;
    left: 0;
    transition: all 300ms;
    z-index: 2000;
    border-radius: 30px 30px 0px 0px;
    max-height: 100vh;
    overflow-y: auto;
}

.sharePopUpShow{
    bottom: 0;
    transition: all 300ms;
}

.sharePopUpHidden{
    bottom: -500px;
    transition: all 300ms;
    /* display: none; */
}

.rettangoloblurShow{
    position: fixed;
    z-index: 1500;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.7);
    visibility: visible;
    transition: all 100ms;
}

.rettangoloblurHidden{
    position: fixed;
    z-index: 1500;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.7);
    visibility: hidden;
    transition: all 100ms;
}

.selectPopUp{
    position: fixed;
    z-index: 1500;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-content: center;
    align-items: center;
}

.selectPopUpShow{
    visibility: visible;
}

.selectPopUpHidden{
    visibility: hidden;
}
.ricerca{    
    display: flex;
    flex-flow: column wrap;
    grid-gap: 20px;
    gap: 20px;
}

.ricettario{    
    display: flex;
    flex-flow: column wrap;
    justify-content: flex-start;
    align-content: center;
    align-items: center;
    grid-gap: 20px;
    gap: 20px;
}

.slideAppRicettario{
    display: flex;
    flex-flow: column wrap;
    justify-content: flex-start;
    align-content: center;
    align-items: center;
    grid-gap: 10px;
    gap: 10px;
}

.slideChipsRicettario{
    width: calc(100vw - 58px);
    padding: 1px;
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-content: center;
    align-items: center;
    grid-gap: 10px;
    gap: 10px;
    overflow-x: auto;
}

@media only screen and (max-width: 1200px) {
    .slideChipsRicettario{
        /* hide scroll bar */
        -ms-overflow-style: none;  /* Internet Explorer 10+ */
        scrollbar-width: none;  /* Firefox */
    }
    
    .slideChipsRicettario::-webkit-scrollbar { 
        /* hide scroll bar */
        display: none;  /* Safari and Chrome */
    }
}

@media only screen and (min-width: 700px) {
    .slideAppRicettario{
        flex-direction: row;
        justify-content: center;
        max-width: calc(100vw - 60px);
        grid-gap: 25px;
        gap: 25px;
    }
    @media only screen and (min-width: 1200px) {
        .slideChipsRicettario{
            flex-flow: row wrap;
        }
    }

    .ricerca{    
        flex-direction: row;
        justify-content: center;
        max-width: calc(100vw - 60px);
        grid-gap: 25px;
        gap: 25px;
    }
}
.rigaFiltri{
    width: calc(100vw - 60px);    
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.rigaFiltriTxt{
    display: flex;
    flex-direction: row;
    align-items: center;
    font-weight: 400;
    font-size: 18px;
    cursor: pointer;
}

.rigaFiltriTxtDark, .rigaFiltriTxtDark svg path{
    color: var(--primaryDark);
    stroke: var(--primaryDark);
}

.rigaFiltriTxtLight, .rigaFiltriTxtLight svg path{
    color: var(--blu);
    stroke: var(--blu);
}

.visteApplicazioniRigaFiltri{ 
    display: flex;
    flex-direction: row;
}

.frameVistaRigaFiltri{
    display: flex;
    flex-flow: column wrap;
    justify-content: center;
    align-content: center;
    align-items: center;
    height: 48px;
    width: 48px;
    cursor: pointer;
}

.quadratoVistaRigaFiltri{
    height: 24px;
    width: 24px;
    box-sizing: border-box;
    border-radius: 1px;
    display: flex;
    flex-flow: column wrap;
    justify-content: space-between;
    align-content: center;
    align-items: center;
}

.vistaApplicazioni1Dark{
    background-color: var(--primaryDark20);
}

.vistaApplicazioni1Light{
    background-color: var(--blu10);
}

.righettaVista2Filtri{
    border-radius: 1px;
    width: 24px;
    height: 6px;
}

.righettaVista2FiltriDark{
    background-color: var(--primaryDark20);
}

.righettaVista2FiltriLight{
    background-color: var(--blu10);
}

@media only screen and (min-width: 1200px) {
    .rigaFiltriTxt{
        font-size: 20px;
    }
}
.chip{
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    padding: 5px 15px 5px 15px;
    grid-gap: 10px;
    gap: 10px;
    font-size: 16px;
    border-radius: 1000px;
    cursor: pointer;
    width: -webkit-max-content;
    width: max-content;
}

.chipDarkSelected{
    background-color: var(--secondaryDark);
    color: var(--sfondoDark);
}

.chipDarkUnselected{
    background-color: var(--backgroundTileDark);
    color: var(--primaryDark);
}

.chipLightSelected{
    background-color: var(--bluSecondario);
    color: var(--sfondoLight);
}

.chipLightUnselected{
    background-color: var(--bianco);
    color: var(--blu);
    border: 0.8px solid var(--blu);
}

/*  */

.chipDarkSelected *{
    fill: var(--sfondoDark);
}

.chipDarkUnselected *{
    fill: var(--primaryDark);
}

.chipLightSelected *{
    fill: var(--sfondoLight);
}

.chipLightUnselected *{
    fill: var(--blu);
}

@media only screen and (min-width: 1200px) {
    .chip{
        font-size: 18px;
    }
}
.filtri{    
    display: flex;
    flex-flow: column wrap;
    align-items: center;
    grid-gap: 20px;
    gap: 20px;
    width: calc(100vw - 60px);
    padding-bottom: 120px;
}

.sezioneFiltro{
    display: flex;
    flex-flow: column wrap;
    grid-gap: 5px;
    gap: 5px;
    width: 100%;
}

.slideFiltriFiltro{
    display: flex;
    flex-flow: row wrap;
    grid-gap: 10px;
    gap: 10px;
    padding-bottom: 10px;
}

.titoloSezioneFiltro{
    font-weight: 600;
    font-size: 18px;
}

.titoloSezioneFiltroDark{
    color: var(--primaryDark);
}

.titoloSezioneFiltroLight{
    color: var(--blu);
}

.vediAppFiltrare{
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    padding: 0 20px;
    border-radius: 1000px;
    cursor: pointer;
    width: -webkit-max-content;
    width: max-content;
    height: 48px;
    position: fixed;
    bottom: 50px;
    font-weight: 400;
}

@media only screen and (min-width: 1200px) {
    .titoloSezioneFiltro{
        font-size: 22px;
    }
}
.dettaglioApp{
    display: flex;
    flex-flow: column wrap;
    align-items: center;
    grid-gap: 20px;
    gap: 20px;
    margin-top: 60px;
}

.fotoRicettaDettaglio{
    background-position: center;
    background-size: cover;
    width: 100vw;
    height: calc(100vw * 9/16);
}

.dettaglioAppBody{
    box-sizing: border-box;
    padding: 0 30px;
    width: 100vw;
    display: flex;
    flex-flow: column wrap;
    align-items: flex-start;
    grid-gap: 30px;
    gap: 30px;
}

.dettaglioAppDark{
    color: var(--primaryDark);
}

.dettaglioAppLight{
    color: var(--blu);
}

.appSubTitle{
    font-weight: 600;
    font-size: 18px;
}

.appFlexSezioni{    
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    grid-gap: 10px;
    gap: 10px;
    width: 100%;
}

.appTestoNote{
    font-weight: 300;
    font-size: 16px;
}

.appChip{
    border-radius: 10px;
    box-sizing: border-box;
    padding: 2px 10px;
    width: -webkit-max-content;
    width: max-content;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    grid-gap: 5px;
    gap: 5px;
}

.appChipDark{
    color: var(--primaryDark);
    background-color: var(--backgroundTileDark);
}

.appChipDark path{
    fill: var(--primaryDark);
}

.appChipLight{
    color: var(--blu);
    border: 0.8px solid var(--blu);
    background-color: var(--sfondoLight);
}

.appChipLight path {
    fill: var(--blu);
}

.slideChipsApp{
    display: flex;
    flex-flow: row nowrap;
    align-items: stretch;
    grid-gap: 10px;
    gap: 10px;
    max-width: calc(100vw - 60px);
    overflow-x: auto;
    padding: 5px;
    box-sizing: border-box;
}

.appFlexTileDati{
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-content: center;
    align-items: center;
    width: 100%;
}

.tileDatiPrincipaliApp{
    display: flex;
    flex-flow: column wrap;
    justify-content: center;
    align-content: center;
    align-items: center;
    width: 25%;
}

.tileDatiPrincipaliAppTxt{
    font-weight: 300;
    font-size: 13px;
}

.appTable{
    display: flex;
    flex-flow: column wrap;
    justify-content: space-between;
    align-content: center;
    width: 100%;
    grid-gap: 10px;
    gap: 10px;
}

.appTableRow{
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-content: center;
    align-items: stretch;
    width: 100%;
    grid-gap: 2px;
    gap: 2px;
}

.appTableCol{
    display: flex;
    flex-flow: column wrap;
    justify-content: center;
    align-content: center;
    align-items: center;
}

.appTableValue{
    font-weight: 600;
    font-size: 24px;
    text-align: center;
}

.appTableDescription{
    font-weight: 400;
    font-size: 13px;    
    line-height: 20px;
    letter-spacing: 0.4px;
    text-align: center;
}

.unitaDiMisura{
    font-weight: 300;
}

.rigaV{
    width: 1px;
    border-radius: 1000px;
}

.rigaDark{
    background-color: var(--primaryDark50);
}

.rigaLight{
    background-color: var(--blu50);
}

.rigaO{
    height: 1px;
    border-radius: 1000px;
    width: 100%;
}

.appIconStrokeDark path{
    stroke: var(--primaryDark);
}
.appIconFillDark path{
    fill: var(--primaryDark);
}

.slideTileFunzioneApp{
    display: flex;
    flex-flow: row nowrap;
    align-items: stretch;
    grid-gap: 20px;
    gap: 20px;
    max-width: calc(100vw - 60px);
    overflow-x: auto;
    padding: 10px;
    box-sizing: border-box;
}

.appTileFunzione{
    min-height: calc(100vw *0.47);
    min-width: calc(100vw *0.47);
    border-radius: 5px;
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    align-items: center;
    padding: 5px;
    text-align: center;
}

.appTileFunzioneDark{
    background-color: var(--backgroundTileDark);
}

.appTileFunzioneLight{
    background-color: var(--sfondoLight);
    box-shadow: var(--ombra);
}

.appTileTitle{
    font-weight: 400;
    font-size: 13px;    
    line-height: 20px;
    letter-spacing: 0.4px;
}

.appTileTime, .appTileIntervallo{
    font-weight: 300;
    font-size: 18px;
    letter-spacing: 0.15px;
}

.appGraficoTempo{
    min-width: calc(46% + 20px);
    min-height: calc(46% + 20px);
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
}

.cerchioTrasparenteAppTile{
    width: 90%;
    height: 90%;
    border-radius: 50%;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
}

@media only screen and (max-width: 1200px) {
    .slideChipsApp{
        /* hide scroll bar */
        -ms-overflow-style: none;  /* Internet Explorer 10+ */
        scrollbar-width: none;  /* Firefox */
    }
    
    .slideChipsApp::-webkit-scrollbar { 
        /* hide scroll bar */
        display: none;  /* Safari and Chrome */
    }

    .slideTileFunzioneApp{
        /* hide scroll bar */
        -ms-overflow-style: none;  /* Internet Explorer 10+ */
        scrollbar-width: none;  /* Firefox */
    }
    
    .slideTileFunzioneApp::-webkit-scrollbar { 
        /* hide scroll bar */
        display: none;  /* Safari and Chrome */
    }
}

.appFlexSubtitleAndFrecce{
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-content: center;
    align-items: center;
    width: 100%;
}

.appFlexArrows{
    display: flex;
    flex-flow: row wrap;
}

.appFlexArrowsDark svg{
    stroke: var(--primaryDark);
    fill: none;
}

.appFlexArrowsLight svg{
    stroke: var(--blu);
    fill: none;
}

.notGreyArrowApp{
    cursor: pointer;
    transition: opacity 800ms;
}

.greyArrowApp{
    opacity: 0.3;
    transition: opacity 800ms;
}

.appTileFunzione{
    transition: all 800ms;
}

@media only screen and (min-width: 700px){
    .appTileFunzione{
        min-height: 170px;
        min-width: 170px;
    }
    @media only screen and (min-width: 1200px) {
        .appSubTitle{
            font-size: 22px;
        }

        .tileDatiPrincipaliAppTxt{
            font-size: 15px;
        }

        .appTileTitle{
            font-size: 15px;
        }

        .appTileIntervallo{
            font-size: 18px;
        }

        .appTileTime{
            font-size: 18px;
            padding: 40% 0 40%;
        }

        .appTestoNote{
            font-size: 18px;
        }

        .appTableDescription{
            font-size: 15px;
        }

        .appTableValue{
            font-size: 26px;
        }

        .appGraficoTempo{            
            min-width: calc(48% + 20px);
            min-height: calc(48% + 20px);
        }

        .fotoRicettaDettaglio{           
            flex: 1 1;
            border-radius: 5px;
            width: 30%;
            height: calc(40vw * 9/16);
        }

        .slideTileFunzioneApp{
            overflow-x: hidden;
        }
        .dettaglioAppBody{
            padding: 0;
            width: 60%;
        }

        .dettaglioApp{
            width: calc(100% - 100px);
            flex-flow: row;
            grid-gap: 50px;
            gap: 50px;
            margin-top: 0;
        }

        .tileDatiPrincipaliApp{
            flex-flow: row;
            justify-content: flex-start;
            min-height: 48px;
            width: auto;
        }

        .appFlexTileDati{
            justify-content: flex-start;
            grid-gap: 40px;
            gap: 40px;
        }

        .valueTableDesktop{
            width: 60%;
        }

        .slideTileFunzioneApp{
            max-width: 100%;
        }
    }
}
.formSpreadSheet{
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-start;
    align-content: center;
    align-items: center;
    width: 100vw;
    height: 100vh;
    padding: 80px 30px;
    box-sizing: border-box;
    grid-gap: 20px;
    gap: 20px;
    overflow-y: auto;
}

.urlFormSpreadsheet{
    margin-bottom: 50px;
}

.flexColMsgUrl{
    display: flex;
    flex-flow: column nowrap;
}

button{
    cursor: pointer;
}

.inputTextForm{
    width: 100%;
}

.inputTextForm input, .inputTextForm select{
    border: var(--grigio) solid 1px;
    border-radius: 5px;
    height: 30px;
    text-indent: 5px;
    width: 100%;
}

.inputTextForm input::-webkit-input-placeholder{
    text-indent: 5px;
}

.inputTextForm input:-ms-input-placeholder{
    text-indent: 5px;
}

.inputTextForm input::placeholder{
    text-indent: 5px;
}

.descriprionInputForm{
    font-weight: 600;
    font-size: 18px;
}

.navButtonFormSpreadsheet{
    display: flex;
    flex-flow: row;
    justify-content: center;
    grid-gap: 40px;
    gap: 40px;
    width: 100%;
}

.flexInputTempoForm{
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: center;
    grid-gap: 10px;
    gap: 10px;
}

.messaggioCompilazioneForm{
    color: red;
    font-size: 30px;
    font-weight: 600;
    text-align: center;
}

.inputPassword{
    border: var(--grigio) solid 1px;
    border-radius: 5px;
    height: 30px;
    text-indent: 5px;
    width: 100%;
}

.flexPassword{
    display: flex;
    flex-flow: row;
    align-items: center;
}

.flexCaricamentoImmagine{
    display: flex;
    flex-flow: column;
}

.TitoloRecap{
    color: rgb(67, 123, 227);
    font-weight: 600;
}

.divImmagineForm{
    display: flex;
    flex-flow: row;
    align-items: center;
    grid-gap: 10px;
    gap: 10px;
}

.immagineForm{
    width: 30vw;
}

@media only screen and (min-width: 500px) {
    .inputTextForm{
        width: 48%;
        min-width: 48%;
    }
    @media only screen and (min-width: 1200px) {
        .inputTextForm{
            width: 30%;
            min-width: 30%;
        }
    }
}

.popUpCaricamentoForm{
    position: fixed;
    width: 250px;
    height: 200px;
    border-radius: 20px;
    background-color: var(--blu10);
    display: flex;
    flex-flow: column wrap;
    justify-content: center;
    align-items: center;
    align-content: center;
    grid-gap: 20px;
    gap: 20px;
    color: var(--blu);
    top: 100px;
}

