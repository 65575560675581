.tour{
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1010;
    width: 100vw;
    height: 100%;
    box-sizing: border-box;
    text-align: center;
    background-color: transparent;
    transition: background-color 1000ms linear;
}

.paginaBluTour{
    background-color: var(--bluSecondario);
    color: var(--bianco);
    padding: 30px 30px 100px;

    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    align-items: center;
}

.skipTour{
    font-weight: 600;
    font-size: 15px;    
    letter-spacing: 1.25px;
    min-height: 48px;
    min-width: 48px;
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    gap: 5px;
    z-index: 1100;
}

.skipPaginaBlu{
    position: fixed;
    bottom: 10px;
    right: 30px;
    color: var(--bianco);
}

.txtPrimoAccesso{
    font-weight: 400;
    font-size: 20px;
}

.iconaNavBarTour{
    border-radius: 1000px;
    background-color: var(--sfondoLight);
    height: 48px;
    width: 48px;
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
}

.paginaTutorial{
    background-color: rgba(0, 0, 0, 0.3);
}


.flexMidPaginaTutorial{
    z-index: 1100;
    position: fixed;
    bottom: 20%;

    font-weight: 400;
    font-size: 20px;

    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    width: 100%;

    gap: 10px;

    box-sizing: border-box;
    padding: 0 30px;
}

.txtPaginaTutorial{
    max-width: 100%; 
    color: transparent;
    transition: all 800ms;
}

.txtPaginaTutorialEnd{
    color: var(--bianco);   
}

.continuaPaginaTour{
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: center;
    gap: 10px;
    font-style: normal;
    cursor: pointer;
    min-width: 48px;
    min-height: 48px;
    color: transparent;
    transition: all 800ms;
}

.continuaPaginaTour svg path{
    fill: transparent;
    transition: all 800ms;
}

.continuaPaginaTourEnd{
    color: var(--bianco);
}

.continuaPaginaTourEnd svg path{
    fill: var(--bianco);
}



.skipPaginaTutorial{
    position: fixed;
    bottom: 60px;
    right: 30px;
    color: transparent;
    transition: all 800ms;
}

.skipPaginaTutorial svg path{
    stroke: transparent;
    transition: all 800ms;
}

.skipPaginaTutorialEnd{
    color: var(--bianco);
}

.skipPaginaTutorialEnd svg path{
    stroke: var(--bianco);
    transition: all 800ms;
}

.cerchioTourBegin{
    width: 0px;
    height: 0px;
    transition: all 1000ms cubic-bezier(0, 0, 0.57, 1.49);
    border-radius: 1000px;
    position: fixed;
    border: 0px solid var(--bluSecondario);
}

.cerchioTourBeginCenter{
    bottom: 0px;
    left: 50vw;
}


.cerchioTourBeginLeft{
    bottom: 0px;
    left: 13vw;
}

.cerchioTourBeginRight{
    bottom: 0px;
    left: calc(100vw - 13vw);
}

.cerchioTourEndCenter{
    bottom: -50vh;
    left: calc(50vw - 50vh);
    border-width: 50vh;
}

.cerchioTourEndLeft{
    bottom: -55vh;
    left: calc(13vw - 55vh);
    border-width: 55vh;
}

.cerchioTourEndRight{
    bottom: -55vh;
    left: calc(100vw - 13vw - 55vh);
    border-width: 55vh;
}

@media only screen and (max-height: 730px) {
    .cerchioTourEndCenter{
        bottom: -55vh;
        left: calc(50vw - 55vh);
        border-width: 55vh;
    }

    .cerchioTourEndLeft{
        bottom: -65vh;
        left: calc(13vw - 65vh);
        border-width: 65vh;
    }
    
    .cerchioTourEndRight{
        bottom: -65vh;
        left: calc(100vw - 13vw - 65vh);
        border-width: 65vh;
    }

    @media only screen and (max-height: 450px) {
        .flexMidPaginaTutorial{
            bottom: 120px;
        }
    }
}

@media only screen and (max-width: 340px) {
    .cerchioTourEndCenter{
        bottom: -65vh;
        left: calc(50vw - 65vh);
        border-width: 65vh;
    }

    .cerchioTourEndLeft{
        bottom: -67vh;
        left: calc(13vw - 67vh);
        border-width: 67vh;
    }
    
    .cerchioTourEndRight{
        bottom: -67vh;
        left: calc(100vw - 13vw - 67vh);
        border-width: 67vh;
    }
}

@media (orientation: landscape) {

    .cerchioTourEndCenter{
        bottom: -62vw;
        left: calc(50vw - 62vw);
        border-width: 62vw;
    }
    
    .cerchioTourEndLeft{
        bottom: -87vw;
        left: calc(13vw - 87vw);
        border-width: 87vw;
    }
    
    .cerchioTourEndRight{
        bottom: -87vw;
        left: calc(100vw - 13vw - 87vw);
        border-width: 87vw;
    }

    @media only screen and (max-width: 600px) {
        .txtPaginaTutorial {
            width: 95%;
        }
    }
}