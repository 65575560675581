.share{    
    padding: 10px 30px 20px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-weight: 600;
    gap: 30px;
    border-radius: 30px 30px 0px 0px;
    text-align: center;
}

.shareDark{
    background-color: var(--backgroundTileDark);
    color: var(--primaryDark);
}

.shareLight{
    background-color: var(--sfondoLight);
    color: var(--blu);
}

.shareTitle{
    font-size: 20px;
}

.bottoneAnnullaShareDark{
    width: 100%;
    height: 48px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: var(--primaryDark20);
    border-radius: 1000px;
}

.bottoneAnnullaShareLight{
    width: 100%;
    height: 48px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: var(--blu10);
    border-radius: 1000px;
}

.slideIconeShare{
    width: 100%;
    display: flex;
    flex-flow: row wrap;
}

.tileShare{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 33%;
    padding: 0 5px;
    box-sizing: border-box;
    cursor: pointer;
}

.colIconeShare{
    display: flex;
    width: 100%;
    flex-flow: column;
    gap: 25px;
}

@media only screen and (min-width: 700px) {
    .share{    
        padding: 10px 20vw 20px;
    }
}