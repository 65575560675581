.chip{
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    padding: 5px 15px 5px 15px;
    gap: 10px;
    font-size: 16px;
    border-radius: 1000px;
    cursor: pointer;
    width: max-content;
}

.chipDarkSelected{
    background-color: var(--secondaryDark);
    color: var(--sfondoDark);
}

.chipDarkUnselected{
    background-color: var(--backgroundTileDark);
    color: var(--primaryDark);
}

.chipLightSelected{
    background-color: var(--bluSecondario);
    color: var(--sfondoLight);
}

.chipLightUnselected{
    background-color: var(--bianco);
    color: var(--blu);
    border: 0.8px solid var(--blu);
}

/*  */

.chipDarkSelected *{
    fill: var(--sfondoDark);
}

.chipDarkUnselected *{
    fill: var(--primaryDark);
}

.chipLightSelected *{
    fill: var(--sfondoLight);
}

.chipLightUnselected *{
    fill: var(--blu);
}

@media only screen and (min-width: 1200px) {
    .chip{
        font-size: 18px;
    }
}