.rigaFiltri{
    width: calc(100vw - 60px);    
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.rigaFiltriTxt{
    display: flex;
    flex-direction: row;
    align-items: center;
    font-weight: 400;
    font-size: 18px;
    cursor: pointer;
}

.rigaFiltriTxtDark, .rigaFiltriTxtDark svg path{
    color: var(--primaryDark);
    stroke: var(--primaryDark);
}

.rigaFiltriTxtLight, .rigaFiltriTxtLight svg path{
    color: var(--blu);
    stroke: var(--blu);
}

.visteApplicazioniRigaFiltri{ 
    display: flex;
    flex-direction: row;
}

.frameVistaRigaFiltri{
    display: flex;
    flex-flow: column wrap;
    justify-content: center;
    align-content: center;
    align-items: center;
    height: 48px;
    width: 48px;
    cursor: pointer;
}

.quadratoVistaRigaFiltri{
    height: 24px;
    width: 24px;
    box-sizing: border-box;
    border-radius: 1px;
    display: flex;
    flex-flow: column wrap;
    justify-content: space-between;
    align-content: center;
    align-items: center;
}

.vistaApplicazioni1Dark{
    background-color: var(--primaryDark20);
}

.vistaApplicazioni1Light{
    background-color: var(--blu10);
}

.righettaVista2Filtri{
    border-radius: 1px;
    width: 24px;
    height: 6px;
}

.righettaVista2FiltriDark{
    background-color: var(--primaryDark20);
}

.righettaVista2FiltriLight{
    background-color: var(--blu10);
}

@media only screen and (min-width: 1200px) {
    .rigaFiltriTxt{
        font-size: 20px;
    }
}