.navbar{
    width: 100vw;
    height: 60px;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    padding: 0 30px;
    box-sizing: border-box;
    position: fixed;
    bottom: 0;
    z-index: 1000;
}

.navbarDark{
    background-color: var(--backgroundTileDark);
}

.navbarLight{
    box-shadow: 0px -1px 7px rgba(0, 0, 0, 0.25);
    background-color: var(--sfondoLight);
}

.iconaNavBar{
    width: 48px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.iconaNavBarSvgDark path{
    stroke: var(--bianco);
}

.iconaNavBarSvgLight path{
    stroke: var(--blu);
}

.iconaUserDark path{
    fill: var(--bianco);
}

.iconaUserLight path{
    fill: var(--blu);
}

.currentPageDark, .currentPageUserDark{
    box-shadow: inset 0px 3px 0px var(--secondaryDark);
}

.currentPageLight, .currentPageUserLight{
    box-shadow: inset 0px 3px 0px var(--bluSecondario);
}

.currentPageDark svg path{
    stroke: var(--secondaryDark);
}

.currentPageLight svg path{
    stroke: var(--bluSecondario);
}

.currentPageUserDark svg path{
    fill: var(--secondaryDark);
}

.currentPageUserLight svg path{
    fill: var(--bluSecondario);
}