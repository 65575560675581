.categoria{
    display: flex;
    flex-flow: column wrap;
    gap: 20px;
}

@media only screen and (min-width: 700px) {
    .categoria{
        flex-direction: row;
        justify-content: center;
        max-width: calc(100vw - 60px);
        gap: 25px;
    }
}