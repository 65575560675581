.wrapper{
    display: flex;
    flex-flow: column;
    align-items: center;
    padding: 80px 0;
}

.wrapper2{    
    display: flex;
    flex-flow: column;
    align-items: center;
    padding: 80px 0 30px;
}

.wrapper3{
    display: flex;
    flex-flow: column;
    align-items: center;
    padding: 0 0 30px; 
}

@media only screen and (min-width: 1200px) {
    .wrapper{
        padding: 80px 0 30px;
    }

    .wrapper3{
        padding: 80px 0 30px; 
    }
}
