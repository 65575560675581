.ricerca{    
    display: flex;
    flex-flow: column wrap;
    gap: 20px;
}

.ricettario{    
    display: flex;
    flex-flow: column wrap;
    justify-content: flex-start;
    align-content: center;
    align-items: center;
    gap: 20px;
}

.slideAppRicettario{
    display: flex;
    flex-flow: column wrap;
    justify-content: flex-start;
    align-content: center;
    align-items: center;
    gap: 10px;
}

.slideChipsRicettario{
    width: calc(100vw - 58px);
    padding: 1px;
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-content: center;
    align-items: center;
    gap: 10px;
    overflow-x: auto;
}

@media only screen and (max-width: 1200px) {
    .slideChipsRicettario{
        /* hide scroll bar */
        -ms-overflow-style: none;  /* Internet Explorer 10+ */
        scrollbar-width: none;  /* Firefox */
    }
    
    .slideChipsRicettario::-webkit-scrollbar { 
        /* hide scroll bar */
        display: none;  /* Safari and Chrome */
    }
}

@media only screen and (min-width: 700px) {
    .slideAppRicettario{
        flex-direction: row;
        justify-content: center;
        max-width: calc(100vw - 60px);
        gap: 25px;
    }
    @media only screen and (min-width: 1200px) {
        .slideChipsRicettario{
            flex-flow: row wrap;
        }
    }

    .ricerca{    
        flex-direction: row;
        justify-content: center;
        max-width: calc(100vw - 60px);
        gap: 25px;
    }
}